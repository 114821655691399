<template>
    <div class="navigation-breadcrumbs">
        <ol class="navigation-breadcrumbs__list">
            <li class="navigation-breadcrumbs__list__items" v-for="route in crumbs">
                <router-link :to="route">{{getLabelOf(route)}}</router-link>
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    methods: {
        getLabelOf(route) {
            return this.$t('navigation.labels.' + (route.meta?.label ?? route.name ?? '#MISSING#'), this.$route.params);
        }
    },

    computed: {
        crumbs() {
            let crumbs = [
                this.$router.resolve({name: 'index'})
            ];

            if(this.$route.meta.breadcrumbs) {
                _.forEach(this.$route.meta.breadcrumbs, (name) => {
                    let crumb = this.$router.resolve({name});

                    if(!crumb.meta.hidden) {
                        crumbs.push(crumb);
                    }
                });
            }

            crumbs.push(this.$route);

            return crumbs;
        }
    }
}
</script>

<style>
@import "@scss/components/navigationBreadcrumbs.scss";
</style>
