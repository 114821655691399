export default {
    procurements: {
        create: {
            title: 'Új kérvény űrlap igénylése',
            content: 'Szeretne igényelni egy új űrlapot? Az elbírásálra be nem nyújtott űrlapok 60 nap után automatikusan törlésre kerülnek!'
        },
        delete: {
            title: 'Biztosan törölni akarja?',
            content: 'Az alábbi kérvény törlésének megerősítése.'
        }
    }
};
