import BroadcastChannel from "../plugins/BroadcastChannel.js";

/**
 * @example this.$broadcast.postMessage(new BroadcastBag({example: 'data'}));
 * @example new BroadcastBag({example: 'data'}).dispatch();
 */
export class BroadcastBag {
    constructor(data = null) {
        this.data = data;
    }

    dispatch() {
        const Channel = new BroadcastChannel();

        Channel.postMessage(this);

        return this;
    }
}

export class ApplicationMounted extends  BroadcastBag {}
export class LoginEvent extends BroadcastBag {}
export class LogoutEvent extends BroadcastBag {}
export class CookieReminderAccepted extends BroadcastBag {}

