export default {
    forint: {
        short: 'HUF',
        long: 'Forint'
    },
    euro: {
        short: 'EUR',
        long: 'Euro'
    },
    gbp: {
        short: 'GBP',
        long: 'Angol font'
    },
    usd: {
        short: 'USD',
        long: 'Amerikai dollár'
    },
    chf: {
        short: 'CHF',
        long: 'Svácji frank'
    },
};
