export default {
    'editInProgress': 'Szerkesztés alatt',
    'sentToSuperior': 'Benyújtva szervezeti vezetőnek',
    'rejectedBySuperior': 'Szervezeti vezető elutasította',
    'sentToCoordinator': 'Koordinátornak küldve',
    'inPreparation': 'Szakmai előkészítés alatt',
    'inCorrelationByCoordinator': 'Egyeztetés alatt a kérvényezővel',
    'inCorrelationByCountersignator': 'Egyeztetés alatt a koordinátorral',
    'inCorrelationByLiabilityProvider': 'Egyeztetés alatt a ellenjegyzővel',
    'sentToCountersignation': 'Ellenjegyzésre küldve',
    'countersigned': 'Ellenjegyezve',
    'sentToLiabilityProvider': 'Kötelezettségvállalásra küldve',
    'acceptedByLiabilityProvider': 'Kötelezettségvállaló jóváhagyta',
    'rejectedByLiabilityProvider': 'Kötelezettségvállaló elutasította',
    'rejectedByCountersignation': 'Ellenjegyző elutasította',
};
