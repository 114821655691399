export default class Formatter {
    constructor() {
        this.options = {};
    }

    install(app, options) {
        this.options = options;

        const exposed = {
            filesize: this.filesize.bind(this),
            groupNumbers: this.groupNumbers.bind(this),
            date: this.date.bind(this)
        };

        /**
         * Example:
         * this.$root.$formatter
         */
        app.config.globalProperties.$formatter = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const Formatter = inject('Formatter')
         */
        app.provide('Formatter', exposed);
    }

    /**
     * @param {integer} bytes
     * @param {integer} decimals Displayed precision
     * @param {boolean} binary Binary or decimal magnitude
     * @returns {string}
     */
    filesize(bytes, decimals, binary) {
        // Keep it without type comparison or null and similar "bytes" will not enter this early return!
        if(bytes == 0) {
            return '0 b';
        }

        let kilo = binary || false ? 1024 : 1000,
            decimalsLength = decimals || 2,
            sizes = binary || false ?
                ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] :
                ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            index = Math.floor(Math.log(bytes) / Math.log(kilo));

        return parseFloat((bytes / Math.pow(kilo, index)).toFixed(decimalsLength)) + ' ' + sizes[index];
    }

    /**
     * Formats numbers into strings and separates the thousand groups by the separator.
     *
     * @param number
     * @param size
     * @param separator
     * @param decimalSeparator
     * @returns {string}
     */
    groupNumbers(number, size = 3, separator = ' ', decimalSeparator = '.') {
        if(!number || isNaN(number)) {
            return number;
        }

        let strings = number.toString().split(decimalSeparator);

        _.forEach(strings, function(value, index) {
            strings[index] = value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
        });

        return strings.join(decimalSeparator);
    }

    /**
     *
     * @param {Date|String} date
     * @param {String} format 'Y-m-d H:i:s'
     * @returns {string}
     */
    date(date, format = 'Y-m-d') {
        if(date === null || date === undefined) {
            return date;
        }

        if(typeof date !== 'object') {
            date = new Date(date);
        }

        var string = '',
            mo = date.getMonth(),   // month (0-11)
            m1 = mo+1,			    // month (1-12)
            dow = date.getDay(),    // day of week (0-6)
            d = date.getDate(),     // day of the month (1-31)
            y = date.getFullYear(), // 1999 or 2003
            h = date.getHours(),    // hour (0-23)
            mi = date.getMinutes(), // minute (0-59)
            s = date.getSeconds();  // seconds (0-59)

        for (var i = 0, len = format.length; i < len; i++) {
            switch(format[i])
            {
                case 'j': // Day of the month without leading zeros  (1 to 31)
                    string+= d;
                    break;

                case 'd': // Day of the month, 2 digits with leading zeros (01 to 31)
                    string+= (d < 10) ? "0"+d : d;
                    break;

                case 'l': // (lowercase 'L') A full textual representation of the day of the week
                    var days = Array("Vasárnap","Hétfő","Kedd","Szerda","Csütörtök","Péntek","Szombat");
                    string+= days[dow];
                    break;

                case 'w': // Numeric representation of the day of the week (0=Sunday,1=Monday,...6=Saturday)
                    string+= dow;
                    break;

                case 'D': // A textual representation of a day, three letters
                    days = Array("V","H","K","Sze","Cs","P","Szo");
                    string+= days[dow];
                    break;

                case 'm': // Numeric representation of a month, with leading zeros (01 to 12)
                    string+= (m1 < 10) ? "0"+m1 : m1;
                    break;

                case 'n': // Numeric representation of a month, without leading zeros (1 to 12)
                    string+= m1;
                    break;

                case 'F': // A full textual representation of a month, such as January or March
                    var months = Array("Január","Február","Március","Április","Május","Június","Július","Augusztus","Szeptember","Október","November","December");
                    string+= months[mo];
                    break;

                case 'M': // A short textual representation of a month, three letters (Jan - Dec)
                    months = Array("Jan","Feb","Mar","Ápr","Máj","Jún","Júl","Aug","Szep","Okt","Nov","Dec");
                    string+= months[mo];
                    break;

                case 'Y': // A full numeric representation of a year, 4 digits (1999 OR 2003)
                    string+= y;
                    break;

                case 'y': // A two digit representation of a year (99 OR 03)
                    string+= y.toString().slice(-2);
                    break;

                case 'H': // 24-hour format of an hour with leading zeros (00 to 23)
                    string+= (h < 10) ? "0"+h : h;
                    break;

                case 'g': // 12-hour format of an hour without leading zeros (1 to 12)
                    var hour = (h===0) ? 12 : h;
                    string+= (hour > 12) ? hour -12 : hour;
                    break;

                case 'h': // 12-hour format of an hour with leading zeros (01 to 12)
                    hour = (h===0) ? 12 : h;
                    hour = ( hour > 12) ? hour -12 : hour;
                    string+= (hour < 10) ? "0"+hour : hour;
                    break;

                case 'a': // Lowercase Ante meridiem and Post meridiem (am or pm)
                    string+= (h < 12) ? "am" : "pm";
                    break;

                case 'i': // Minutes with leading zeros (00 to 59)
                    string+= (mi < 10) ? "0"+mi : mi;
                    break;

                case 's': // Seconds, with leading zeros (00 to 59)
                    string+= (s < 10) ? "0"+s : s;
                    break;

                case 'c': // ISO 8601 date (eg: 2012-11-20T18:05:54.944Z)
                    string+= date.toISOString();
                    break;

                default:
                    string+= format[i];
            }
        }

        return string;
    }
};
