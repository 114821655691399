export default class {
    #configuration = Object.freeze(_.cloneDeep(window.frontendConfiguration));

    install(app, options) {
        const exposed = {
            get: this.get.bind(this),
            resolveAsUrl: this.resolveAsUrl.bind(this)
        };

        /**
         * Example:
         * this.$root.$configuration
         */
        app.config.globalProperties.$configuration = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const ConfigurationProvider = inject('ConfigurationProvider')
         */
        app.provide('ConfigurationProvider', exposed);

        if(import.meta.env.DEV) {
            console.log(this.#configuration);
        }
    }

    /**
     * @param {string} key Dot separated keychain
     * @param {any} def Default
     */
    get(key, def = undefined) {
        return _.get(this.#configuration, key, def);
    }

    resolveAsUrl(key, options) {
        let value = this.get(key, null);

        if(!value) {
            return value;
        }

        let target = options?.params ?? {};
        for(let index in target) {
            let param = target[index];
            let replaceKey = `:${index}`;

            value = value.replace(replaceKey, typeof param === 'function' ? param(param(options), index) : param);
        }

        return value;
    }
};
