<template>
    <div class="frame-common" :class="getMainFrameClasses">
        <header class="frame-common__header">
            <a class="frame-common__header__project-title" href="/">Delegatio</a>
            <div class="frame-common__header__title" id="header-title-slot"></div>
            <div class="frame-common__header__buttons">
                <router-link v-if="$user.isAdministrator()" :to="{name: 'administration'}">
                    <button>{{$t('navigation.labels.administration.dashboard')}}</button>
                </router-link>
                <router-link v-if="$user.isLoggedIn()" :to="{name: 'logout'}">
                    <button>{{$t('navigation.logout')}}</button>
                </router-link>
            </div>
        </header>
        <NavigationBreadcrumbs v-if="$user.isLoggedIn()" class="frame-common__breadcrumbs"/>
        <div class="frame-common__secondary-header" id="secondary-header">
            <div class="frame-common__secondary-header__label" id="secondary-header__label"></div>
            <div class="frame-common__secondary-header__control" id="secondary-header__control"></div>
        </div>
        <div class="frame-common__content">
            <router-view class="frame-common__content__view"></router-view>
        </div>
        <CookieReminder class="frame-common__cookie-reminder"/>
    </div>
</template>

<script>
import NavigationBreadcrumbs from "../../components/NavigationBreadcrumbs.vue";
import Button from "../../components/buttons/Button.vue";
import CookieReminder from "../../components/CookieReminder.vue";

export default {
    components: {CookieReminder, Button, NavigationBreadcrumbs},
    data() {return {

    };},
    computed: {
        getMainFrameClasses() {
            return {
                '-guest': !this.$user.isLoggedIn(),
                '-user': this.$user.isLoggedIn(),
            };
        }
    }
}
</script>

<style>
@import "@scss/components/mainFrame.scss";
</style>
