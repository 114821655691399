import * as BroadcastAbles from "../classes/Broadcastables.js";
import {BroadcastBag} from "../classes/Broadcastables.js";

/**
 * Do not move this into the constructor or this.dispatch() will appear in the sender browser tab too!
 * @type {BroadcastChannel}
 */
const Channel = new BroadcastChannel('Application:plugin');

export default class {
    constructor() {
        this.Channel = Channel;
    }

    install(app, option) {
        this.callbackIndex = 0;
        this.callbacks = {};
        this.Channel.onmessage = this.executeCallbacks.bind(this);

        const exposed = {
            add: this.registerCallback.bind(this),
            remove: this.removeCallback.bind(this),
            postMessage: this.postMessage.bind(this)
        };

        /**
         * Example:
         * this.$root.$broadcast
         */
        app.config.globalProperties.$broadcast = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const Broadcast = inject('Broadcast')
         */
        app.provide('Broadcast', exposed);
    }

    async executeCallbacks(event) {
        let message = null;

        /**
         * The original class names are preserved in the imported BroadcastAbles module however the available broadcasted
         * class names are mangled, so we have to use a little search here.
         * The behaviour could be disabled in the vite.config.js by "esbuild.minifyIdentifiers = false;", however then we
         * will lose some file size reduction especially due it won't be applied to the vendor contents too.
         */
        Object.values(BroadcastAbles).forEach((item) => {
            if(item.name === event.data.type) {
                message = new item(event.data.data);
            }
        });

        if(!message) {
            console.error('Received unknown broadcasted event: ', event);
            throw new Error('Unknown event constructor name: ' + event.data.type);
        }

        Object.freeze(message);

        Object.values(this.callbacks).forEach((callback) => {
            callback(message, event);
        });
    }

    /**
     * @param {function} callback
     * @returns {integer} callback id
     */
    registerCallback(callback) {
        this.callbackIndex++;
        this.callbacks[this.callbackIndex] = callback;

        return this.callbackIndex;
    }

    /**
     * @param {integer} id
     * @returns {this}
     */
    removeCallback(id) {
        delete(this.callbacks[id]);

        return this;
    }

    /**
     * @param {BroadcastBag} bag
     * @returns {this}
     */
    postMessage(bag) {
        if(! bag instanceof BroadcastBag) {
            throw new Error('Argument must be an instance of BroadcastBag!');
        }

        this.Channel.postMessage({
            type: bag.constructor.name,
            data: bag.data
        });

        return this;
    }
}
