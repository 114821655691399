<template>
    <div v-if="displayComponent" class="cookie-reminder">
        <p>Todo in footer: impresszum link, cookie, etc.</p>
        <Button @click="acceptTermsOfUseEvent">{{$t('labels.acceptTermsOfUse')}}</Button>
    </div>
</template>

<script>
const cookieName = window.frontendConfiguration.vueJs.cookieNames.cookieReminder ?? 'Cookie-Reminder-State';
import Button from "./buttons/Button.vue";
import Cookies from "js-cookie";

export default {
    components: {Button},

    emits: ['terms-of-use-accepted'],

    data() {return {
        hide: false
    };},

    beforeMount() {
        let value = Cookies.get(cookieName);

        if(value == 1) {
            this.hide = true;
        }
    },

    methods: {
        acceptTermsOfUseEvent() {
            Cookies.set(cookieName, 1, { expires: 365 });

            if(this.$user.isLoggedIn()) {
                this.$user.data.terms_of_use_accepted = true;

                axios.post(window.frontendConfiguration.urls.termsOfUse);
            }

            this.hide = true;

            this.$emit('terms-of-use-accepted', true);
        },
    },

    computed: {
        displayComponent() {
            if(this.hide) {
                return false;
            }

            if(this.$user.isLoggedIn()) {
                return !this.$user.data.terms_of_use_accepted;
            }

            return !Cookies.get(cookieName);
        }
    }
}
</script>

<style lang="scss">
@import "@scss/components/cookieReminder.scss";
</style>
