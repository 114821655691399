import labels from "./hu/labels.js";
import currencies from "./hu/currencies.js";
import navigation from "./hu/navigation.js";
import pageTitles from "./hu/pageTitles.js";
import buttons from "./hu/buttons.js";
import validations from "./hu/validations.js";
import procurementProgressStates from "./hu/procurementProgressStates.js";
import modals from "./hu/modals.js";

export default {
    labels,
    currencies,
    navigation,
    filters: procurementProgressStates,
    pageTitles,
    buttons,
    validations,
    modals
};
