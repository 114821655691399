import lodash from 'lodash';
window._ = lodash;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Do not follow blindly, we will set up our own relocation method
axios.defaults.maxRedirects = 0; // Sets fetch() redirect to "manual"

export function setAxiosIntercreptor(Application) {
// Add a response interceptor
    axios.interceptors.response.use(
        // In case of 2xx
        undefined, // Do nothing with it here
        // Outside 2xx status
        (error) => {
            switch(error.response.status) {
                case 302: // Transforming relocation order into a valid UI action instead of an error
                    if(error.response.data.accepted) {
                        /**
                         * Vue.js will not perform relocation two times if the original action would be
                         * a relocation too to the same url as in the Login component.
                         * Otherwise, double relocation may can happen.
                         */
                        Application.config.globalProperties.$router.replace({
                            path: (error.response.headers.get("content-type") === 'application/json') ?
                                error.response.data.relocate :
                                error.response.data
                        });

                        // This is not an error to be honest but a valid redirection response
                        return Promise.resolve(error.response);
                    }
                    break;
                case 401: // Authentication is required so take the user to the login page
                    Application.config.globalProperties.$router.replace({name: 'login'});
                    break;
            }

            return Promise.reject(error);
        }
    );
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

/*import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
    wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});*/

import { createApp } from 'vue/dist/vue.esm-bundler';
import * as VueRouter from 'vue-router';

// Plugins
import FormatterPlugin from "./plugins/Formatter";
import UserPlugin from "./plugins/User.js";
import { createI18n } from 'vue-i18n';
import BroadcastChannel from "./plugins/BroadcastChannel.js";

// Urls
import commonUrls from "./routes/common";

// Localizations
import hungarian from "../localization/hu.js";

import Frame from "./pages/common/Frame.vue";
import {createPinia} from "pinia";
import Configuration from "./plugins/Configuration.js";
export function createApplication(routes) {
    const I18n = createI18n({
        locale: 'hu',
        fallbackLocale: 'hu',
        messages: {
            hu: hungarian
        }
    });

    const Router = new VueRouter.createRouter({
        history: VueRouter.createWebHistory(),
        routes: _.concat(commonUrls, routes)
    });

    const Application = createApp({
        components: { Frame },
        template: '<Frame/>',

        provide() {
            const provided = {};

            _.forEach([
                'setLocale',
                'getLocale',
            ], (method) => {
                provided[method] = this[method].bind(this);
            });

            return provided;
        },

        beforeMount() {
            // TODO: set locale from cookie, etc
            this.setLocale('hu');
        },

        data() {return {
            localeAllowed: ['hu'],
        };},

        methods: {
            setLocale(locale) {
                if(!this.localeAllowed.includes(locale)) {
                    console.error('Invalid locale: ' + locale);
                    return false;
                }

                if (I18n.mode === 'legacy') {
                    I18n.global.locale = locale
                } else {
                    I18n.global.locale.value = locale
                }

                document.querySelector('html').setAttribute('lang', locale);

                return true;
            },

            getLocale() {
                return I18n.mode === 'legacy' ? I18n.global.locale : I18n.global.locale.value;
            }
        }
    });

    setAxiosIntercreptor(Application);

    let User = new UserPlugin();

    Router.beforeResolve(async (to, from) => {
        let hasPermission = true;
        to.matched.forEach((Route) => {
            if(!hasPermission) {
                return;
            }

            if(Route.meta.permissions.length) {
                hasPermission = User.hasPermission(Route.meta.permissions, {route: {to, from}});
            }
        });

        let hasRole = true;
        to.matched.forEach((Route) => {
            if(!hasRole) {
                return;
            }

            if(Route.meta.roles.length) {
                hasRole = User.hasRole(Route.meta.roles, {route: {to, from}});
            }
        });

        if(!hasPermission || !hasRole) {
            return {name: 'http.status.403'};
        }

        return true;
    });

    return Application
        .use(createPinia())
        .use(User)
        .use(new Configuration())
        .use(Router)
        .use(I18n)
        .use(new FormatterPlugin())
        .use(new BroadcastChannel);
}
