export default {
    logout: 'Kilépés',
    login: 'Belépés',

    labels: {
        '#MISSING#': 'Hiba: címke nem található',
        index: 'Kezdőlap',
        procurements: {
            list: 'Kérvények',
            edit: 'Kérvény #{id} szerkesztése',
            view: 'Kérvény #{id} megtekintése'
        },
        coordination: {
            list: 'Benyújtott kérvények',
            view: 'Kérvény #{id} megtekintése',
            edit: 'Kérvény #{id} előkészítése'
        },
        countersignation: {
            list: 'Benyújtott kérvények',
            view: 'Kérvény #{id} megtekintése',
            edit: 'Kérvény #{id} előkészítése'
        },
        finance: {
            list: 'Benyújtott kérvények',
            view: 'Kérvény #{id} megtekintése',
            edit: 'Kérvény #{id} előkészítése'
        },
        liability: {
            list: 'Benyújtott kérvények',
            view: 'Kérvény #{id} megtekintése',
            edit: 'Kérvény #{id} előkészítése'
        },
        dashboard: 'Főmenü',
        administration: {
            dashboard: 'Adminisztráció',
            users: {
                list: 'Felhasználók',
                edit: 'Felhasználó #{id} szerkesztése',
                view: 'Felhasználó #{id} megtekintése'
            }
        }
    }
};
