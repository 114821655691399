export default {
    copy: 'Másolás',
    yes: 'Igen',
    no: 'Nem',
    cancel: 'Mégse',
    reset: 'Visszaállítás',
    previous: 'Előző',
    next: 'Következő',
    saveAndNext: 'Mentés és tovább',
    send: 'Elküld',
    upload: 'Feltöltés',
    procurements: {
        new: 'Új kérelem',
        save: 'Mentés vázlatként',
        submitProcurementToSuperior: 'Szervezeti vezetőnek küldés'
    },
    submitComment: 'Megjegyzés rögzítése'
};
